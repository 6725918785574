header{
	padding-top: 80px;
	h2{
		font-size: $font-size-base;
		&.gr{
			font-size: $font-size-base*2;
			letter-spacing: 1.5px;
		}
	}
	h1{
		font-size: $font-size-base;
	}
	hr{
		margin: 0;
	}
}
.menu{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 2.5rem;
	.active{
		color: $font-color;
	}
}
.card{
	text-align: left;
	.card-title{
		font-weight: 400;
		margin-bottom: 1rem;
		&h3{
			font-size: 1.125rem;
		}
	}
	.card-body {
		padding: 1.4rem 1rem;
	}
	&.audiobook{
		img{
			height: 300px;
			object-fit: cover;
		}
		& .card-body {
			padding: 1.4rem 1rem 2rem 1rem;
		}
		&.with-audio .card-body {
			padding-bottom: 1rem;
		}
		.green-audio-player{
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			box-shadow: none;
			.slider .gap-progress .pin{
				background-color: $font-color;
			}
			.controls{
				color: $font-color;
			}
			svg path{
				fill: $font-color !important;
			}
		}
	}
	&.event .card-body {
		padding: 1.7rem 1rem;
	}
}

main {
	h2 {
		font-size: 1.125rem;
		margin-bottom: 1rem;
	}
	h3 {
		font-size: 1.125rem;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}
	.row > div > div > h2:not(:first-child) {
		margin-top: 2rem;
	}
}

.event-archive{
	.active{
		color:$font-color;
	}
}