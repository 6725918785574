body{
	font-size: $font-size-base;
	color: $font-color;
	font-family: Arial, Helvetica, Geneva, Swiss, SunSans-Regular;
	min-width: 375px;
	overflow-y: scroll;
	--bs-body-line-height: 1.4;
}
hr{
	color: $font-color;
	opacity: 1;
}
a{
	color: $link-color;
	text-decoration: none;
	&:hover{
		color: $font-color;
	}
}
p{
	margin-bottom: 0.6rem;
}
p.subtitle {
	margin-bottom: 1rem;
	margin-top: -0.5rem;
	font-weight: 600;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	line-height: 1.3;
}
.is-active-0{
	background: #dbdbdb;
}
#projectList,#audiobookList,#organizerList{
	li{
		cursor: grab;
	}
}
#btn-back-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: none;
}
@media (min-width: 1200px) {
	.h1, h1 {
		font-size: 1.75rem;
	}
}